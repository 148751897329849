import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { addDays, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';

import { GetAttendanceDetailOfPlantsRTO } from '../../types/index';

import { getAttendanceDetailOfPlants } from '../../apis/AreaApi';

import { getTimeOffset, toISOStringWithOffset } from '../../utils/getTimeZone';

const useAttendanceOfPlantData = (
  queryKey: QueryKey,
  projectId: string,
  plantId: string,
  areaId: string,
  params: {
    refDate: Date;
    timeZone?: string;
    interval: 'hour' | 'day';
  },
  options?: Omit<
    UseQueryOptions<
      GetAttendanceDetailOfPlantsRTO['plants'][string]['intervals']
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery({
    queryKey,
    queryFn: async () => {
      let intervals: GetAttendanceDetailOfPlantsRTO['plants'][string]['intervals'] =
        {};

      for (let i = 0; i < 6; i += 1) {
        const startOfWeekDate = startOfWeek(
          addDays(startOfMonth(params.refDate), 7 * i),
        );
        const endOfWeekDate = endOfWeek(startOfWeekDate);

        const res = await getAttendanceDetailOfPlants(projectId, {
          fromDate: toISOStringWithOffset(startOfWeekDate, params.timeZone),
          toDate: toISOStringWithOffset(endOfWeekDate, params.timeZone),
          timeZone: getTimeOffset(params.timeZone),
          interval: params.interval,
        });

        intervals = {
          ...intervals,
          ...res.data.data.plants[plantId]?.intervals,
        };
      }

      const result = Object.entries(intervals).reduce(
        (prev, [key, value]) => {
          prev[key] = {
            areas: Object.entries(value.areas)
              .filter(([areaKey]) =>
                areaId === 'all' ? true : areaKey === areaId,
              )
              .reduce((areaPrev, [areaKey, areaValue]) => {
                areaPrev[areaKey] = areaValue;
                return areaPrev;
              }, {}),
          };
          return prev;
        },
        {} as GetAttendanceDetailOfPlantsRTO['plants'][string]['intervals'],
      );

      return result;
    },
    ...options,
  });
};

export default useAttendanceOfPlantData;
